import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Modal,
  Button,
  Col,
  Row,
  InputNumber,
  Form,
  Space,
  Divider,
} from "antd";
import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";

import config from "../../Config";
import { abi } from "../../artifacts/contracts/AcornCredits.json";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { ORDER_CRU } from "../manageOrder/OrderQuery";

const PlaceOrderModal = (props) => {
  const contractAddress = config.CONTRACT_ADDRESS;
  const client = useApolloClient();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contract, setContract] = useState(null);
  const [orderQty, setOrderQty] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    walletConnect();
    // eslint-disable-next-line
  }, []);

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    let tokenId = props.tokenData.tokenId;
    let quantity = orderQty;

    setBtnLoading(true);

    contract
      .reserveCRUs(tokenId, quantity)
      .then((tx) => {
        tx.wait().then((res) => {
          client
            .mutate({
              mutation: ORDER_CRU,
              variables: {
                tokenId,
                quantity,
                transactionHash: tx.hash,
              },
            })
            .then(() => {
              setBtnLoading(false);
              SuccessNotificationMsg("Order placed successfully.");
              props.history.push("/manage-order");
            })
            .catch((e) => {
              ErrorNotificationMsg("Failed to place order.");
              setBtnLoading(false);
            });
        });
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message);
        setBtnLoading(false);
      });
  };

  return (
    <>
      <div className="buybtnwrap">
        <Button type="primary" onClick={showModal}>
          Buy
        </Button>
      </div>
      <Modal
        title="Place Order"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="placeOrderModel"
      >
        <Form
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            quantity: 1,
          }}
        >
          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={24}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 1,
                    max: parseInt(props.tokenData.availableQuantity),
                    message:
                      "Please enter quantity between 1 - " +
                      props.tokenData.availableQuantity,
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter Quantity"
                  onChange={(e) => setOrderQty(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PlaceOrderModal;
