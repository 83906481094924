import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { isAuthenticated } from "../../utils/Helpers";

const { Header, Footer, Content } = Layout;

const FrontLayout = (props) => {
  return (
    <>
      <Layout className="front-layout">
        <Header className="front-header">
          <div className="container">
            <Link to="" className="logo">
              {/* <img src={Logo} alt="" /> */}
              NFT
            </Link>

            <div className="rightdiv">
              <Menu mode="inline">
                <Menu.Item key="Home">
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="About">
                  <Link to="/about-us">About us</Link>
                </Menu.Item>
                <Menu.Item key="Token">
                  <Link to="/token">Token</Link>
                </Menu.Item>
                
              </Menu>
              {isAuthenticated() && (
                <Link to="/dashboard" className="walletlink">
                  Wallet Connected
                </Link>
              )}
            </div>
          </div>
        </Header>

        <Content>{props.children}</Content>
        <Footer>Copyright © NFT, 2021. All rights reserved.</Footer>
      </Layout>
    </>
  );
};

export default FrontLayout;
