import React, { Suspense } from "react";
import { Router } from "react-router-dom";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider, split } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';

import { createBrowserHistory } from "history";
import config from "./Config";
import Routes from "./components/common/Routes";

const history = createBrowserHistory();
const apolloCache = new InMemoryCache();
const token = localStorage.getItem("token");

const uploadLink = createUploadLink({
  uri: config.API_URL,
  headers: {
    "keep-alive": "true",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

const wsLink = new WebSocketLink({
  uri: config.API_WEBSOCKET_URL,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink
);

const client = new ApolloClient({
  cache: apolloCache,
  link: splitLink,
});

const App = () => {
  return (
    <Suspense fallback={"Loading..."}>
      <ApolloProvider client={client}>
        <Router history={history}>
          <Routes />
        </Router>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
