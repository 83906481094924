import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

import Sidebar from "./Sidebar";
import NotificationList from "../manageOrder/NotificationList";
import { isAuthenticated, logout, authUserData } from "../../utils/Helpers";

const { Header, Content } = Layout;

const AdminLayout = (props) => {
  return (
    <div className="AdminApp BusinessApp">
      <Layout>
        <Sidebar />
        <Layout>
          <Header className="front-header">
            <div className="container-fluid">
              <Link to="/" className="logo">
                {/* <img src={Logo} alt="" /> */}
                NFT
              </Link>

              <div className="rightdiv">
                {isAuthenticated() && (
                  <>
                    {authUserData()?.role === "PLATFORM_OPERATOR" && (
                      <NotificationList />
                    )}
                    <Link to="/dashboard" className="walletlink">
                      Wallet Connected
                    </Link>

                    <Link to="" onClick={() => logout()} className="logoutlink">
                      Logout
                    </Link>
                  </>
                )}
              </div>
            </div>
          </Header>
          <Content>
            <div className="contentpart">
              <Layout>
                <Content>
                  <div className="right_content_col">{props.children}</div>
                </Content>
              </Layout>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminLayout;
