import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useApolloClient } from "@apollo/client";
import { debounce } from "lodash";
import { Table, Col, Row, Input, Space, Popconfirm, Button, Tag } from "antd";
import { DollarOutlined, SwapOutlined } from "@ant-design/icons";

import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import { abi } from "../../artifacts/contracts/AcornCredits.json";

import config from "../../Config";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { ORDER_LIST, TRANSFER_CRU, CONFIRM_PAYMENT } from "./OrderQuery";

const { Search } = Input;

const OrderList = (props) => {
  const client = useApolloClient();
  const [apiLoading, setApiLoading] = useState(false);
  const [buyerList, setBuyerList] = useState([]);
  const [state, setState] = useState({
    first: 20,
    skip: 0,
    sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
    limit: 20,
    pageNumber: 1,
    searchQuery: "",
    total: 0,
    where: {},
  });
  const [contract, setContract] = useState(null);
  const [confirmPaymentApiLoading, setConfirmPaymentApiLoading] =
    useState(false);
  const [transferCRUApiLoading, setTransferCRUApiLoading] = useState(false);

  useEffect(() => {
    getOrders(
      state.skip,
      state.sortBy,
      state.first,
      state.searchQuery,
      state.where
    );
  }, []);

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value);
  };

  const onSearch = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));

    getOrders(state.skip, state.sortBy, state.first, value, state.where);
  };

  const debounceLoadData = useCallback(debounce(onSearch, 1000), []);

  const getOrders = (skip, sortBy, first, searchQuery, where) => {
    setApiLoading(true);
    client
      .query({
        query: ORDER_LIST,
        variables: { skip, sortBy, first, searchQuery, where },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        setBuyerList(result.data.getCRUOrders.rows);
        setState((prevState) => ({
          ...prevState,
          total: result.data.getCRUOrders.count,
        }));
        setApiLoading(false);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error in list order.");
        setApiLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    if (pagination.current === 1) {
      await setState((prevState) => ({
        ...prevState,
        skip: 0,
        sortBy: sortBy,
      }));
      getOrders(0, sortBy, state.first, state.searchQuery, state.where);
    } else {
      const skipRecord = (pagination.current - 1) * state.limit;
      await setState((prevState) => ({
        ...prevState,
        skip: skipRecord,
        sortBy: sortBy,
        pageNumber: pagination.current,
      }));
      getOrders(
        skipRecord,
        sortBy,
        state.first,
        state.searchQuery,
        state.where
      );
    }
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "DESC" };

    if (sortField === "status" && sortOrder === "ascend") {
      sortingEnumKey = {
        sortingField: "status",
        sortingOrder: "ASC",
      };
    }
    if (sortField === "status" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "status",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "buyer" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "buyer", sortingOrder: "ASC" };
    }
    if (sortField === "buyer" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "buyer", sortingOrder: "DESC" };
    }

    if (sortField === "isPaymentRecieved" && sortOrder === "ascend") {
      sortingEnumKey = {
        sortingField: "isPaymentRecieved",
        sortingOrder: "ASC",
      };
    }
    if (sortField === "isPaymentRecieved" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "isPaymentRecieved",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "createdAt" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "createdAt", sortingOrder: "ASC" };
    }
    if (sortField === "createdAt" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "createdAt", sortingOrder: "DESC" };
    }

    return sortingEnumKey;
  };

  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    walletConnect();
  }, []);

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const confirmPayment = ({ orderId }) => {
    setConfirmPaymentApiLoading(true);

    client
      .mutate({
        mutation: CONFIRM_PAYMENT,
        variables: { orderId },
      })
      .then(() => {
        setConfirmPaymentApiLoading(false);
        SuccessNotificationMsg("Payment confirmed successfully.");
        getOrders(
          state.skip,
          state.sortBy,
          state.first,
          state.searchQuery,
          state.where
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Failed to confirmed payment.");
        setConfirmPaymentApiLoading(false);
      });
  };

  const transferCRU = ({ buyer, tokenId, quantity, orderId }) => {
    setTransferCRUApiLoading(true);
    const from = localStorage.getItem("address");

    contract
      .safeTransferFrom(from, buyer, tokenId, quantity, "0x")
      .then((tx) => {
        tx.wait().then((res) => {
          client
            .mutate({
              mutation: TRANSFER_CRU,
              variables: { orderId, transactionHash: tx.hash },
            })
            .then(() => {
              setTransferCRUApiLoading(false);
              SuccessNotificationMsg("CRU's transferred successfully.");
              getOrders(
                state.skip,
                state.sortBy,
                state.first,
                state.searchQuery,
                state.where
              );
            })
            .catch((e) => {
              ErrorNotificationMsg("Failed to transfer CRU's");
              setTransferCRUApiLoading(false);
            });
        });
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message);
        setTransferCRUApiLoading(false);
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "RESERVED":
        return "geekblue";
      case "SETTLED":
        return "green";
      default:
        return "volcano";
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case true:
        return "green";
      case false:
        return "volcano";
      default:
        return "volcano";
    }
  };

  const columns = [
    {
      title: "Token ID",
      dataIndex: "tokenId",
      key: "tokenId",
      sorter: false,
    },
    {
      title: "Buyer Public Key",
      dataIndex: "buyer",
      key: "buyer",
      sorter: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: false,
    },
    {
      title: "Status",
      key: "status",
      render: (record) => (
        <Tag color={getStatusColor(record.status)} key={record.status}>
          {record.status}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: "Is Payment Recieved",
      key: "isPaymentRecieved",
      sorter: true,
      render: (record) => (
        <Tag
          color={getPaymentStatusColor(record.isPaymentRecieved)}
          key={record.orderId}
        >
          {record.isPaymentRecieved ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Created Date",
      key: "createdAt",
      sorter: true,
      render: (record) => moment(record.createdAt).format("LLL"),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space>
          {!record.isPaymentRecieved && (
            <Popconfirm
              className="action"
              title="Are you sure, you received payment for tokens ?"
              okText="Yes"
              placement="left"
              onConfirm={() => confirmPayment(record)}
            >
              <Button
                type="link"
                size="small"
                loading={confirmPaymentApiLoading}
              >
                <DollarOutlined title="Confirm Payment" />
              </Button>
            </Popconfirm>
          )}

          {record.status === "RESERVED" && record.isPaymentRecieved && (
            <Popconfirm
              className="action"
              title="Are you sure to transfer CRU ?"
              okText="Yes"
              placement="left"
              onConfirm={() => transferCRU(record)}
            >
              <Button
                type="button"
                size="small"
                loading={transferCRUApiLoading}
              >
                <SwapOutlined title="Transfer CRU" />
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="pagename">Orders List</div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <div className="heading">
            <Row gutter={{ xs: 0 }} align="middle">
              <Col flex="auto"></Col>
              <Col flex="auto" align="end">
                <Space>
                  <div className="searchhwrap">
                    <Search
                      placeholder="Search here"
                      onChange={searchCallbackDelayed}
                    />
                  </div>
                </Space>
              </Col>
            </Row>
          </div>

          <Table
            className="table_grid"
            columns={
              authUserData()?.role === "PLATFORM_OPERATOR"
                ? columns
                : columns.filter((col) => col.key !== "action")
            }
            rowKey={(record) => record["orderId"]}
            dataSource={buyerList}
            scroll={{ x: 970 }}
            loading={apiLoading}
            pagination={{
              defaultCurrent: state.pageNumber,
              defaultPageSize: state.limit,
              total: state.total,
              size: "small",
            }}
            onChange={handleTableChange}
          />
        </section>
      </div>
    </>
  );
};

export default OrderList;
