import React from "react";
import { Switch } from "react-router-dom";

import OpenRoute from "./OpenRoute";
import PrivateRoute from "./PrivateRoute";

/* open Route Components */
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Token from "../pages/Token";
import ActivateAccount from "../authentication/ActivateAccount";

/* Auth Required Private Route Components */
import Dashboard from "../dashboard";
import AddBuyer from "../manageBuyer/AddBuyer";
import BuyerList from "../manageBuyer";

import ImportCSV from "../csvImport/ImportCSV";
import Marketplace from "../csvImport/Marketplace";
import MarketplaceDetail from "../csvImport/MarketplaceDetail";
import MyCRU from "../csvImport/MyCRU";
import CRUDetail from "../csvImport/CRUDetail";

import OrderList from "../manageOrder";
import BlockExplorer from "../blockExplorer";
import CRURegistry from "../blockExplorer/CRURegistry";

/* Other Common Route Components */
import Notfound from "../common/Notfound";

const Routes = () => {
  return (
    <Switch>
      <OpenRoute exact path="/" component={Home} />
      <OpenRoute exact path="/about-us" component={AboutUs} />
      <OpenRoute exact path="/token" component={Token} />
      <OpenRoute
        exact
        path="/activate-account/:activationToken"
        component={ActivateAccount}
      />

      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/add-buyer" component={AddBuyer} />
      <PrivateRoute exact path="/edit-buyer/:buyerId" component={AddBuyer} />
      <PrivateRoute exact path="/manage-buyer" component={BuyerList} />
      <PrivateRoute exact path="/import-csv" component={ImportCSV} />
      <PrivateRoute exact path="/marketplace" component={Marketplace} />
      <PrivateRoute exact path="/my-crus" component={MyCRU} />
      <PrivateRoute
        exact
        path="/marketplace-detail"
        component={MarketplaceDetail}
      />
      <PrivateRoute exact path="/cru-detail" component={CRUDetail} />

      <PrivateRoute exact path="/manage-order" component={OrderList} />
      <PrivateRoute exact path="/block-explorer" component={BlockExplorer} />
      <PrivateRoute exact path="/cru-registry" component={CRURegistry} />

      <OpenRoute exact path="/logout" component={Home} />
      <OpenRoute path="*" component={Notfound} />
    </Switch>
  );
};

export default Routes;
