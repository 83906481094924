import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "./AdminLayout";

import { validateToken, logout } from "../../utils/Helpers";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const address = localStorage.getItem("address");

  const authCheck = () => {
    return validateToken(address);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          window.location.reload();
        } else {
          console.error("No accounts found");
        }
      });

      window.ethereum
        .request({
          method: "eth_accounts",
        })
        .then((res) => {
          if (address !== res[0]) {
            logout();
          }
        });

      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    }
  });

  return (
    <AdminLayout>
      <Route
        {...rest}
        render={(props) => {
          return authCheck() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      />
    </AdminLayout>
  );
};

export default PrivateRoute;
