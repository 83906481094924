import React from "react";
import oval from "../../images/oval.png";
import MetaConnect from "../authentication/MetaConnect";
import "./Home.scss";

const Home = () => {
  return (
    <div className="homepage">
      <div className="container">
        <div className="bannercontent">
          <div className="inside">
            <h3>NFT - Digital Marketplace to buy & sell carbon credits</h3>
            <h5>
              An agroforestry-led carbon trade program that unlocks the
              international carbon market for smallholder farmers.
            </h5>
            <div className="btnrow">
              <MetaConnect />
            </div>
          </div>
        </div>
        <div className="bannerimg">
          <div className="imgwrp">
            <img src={oval} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
