import React, { useState, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row, Input, Space } from "antd";

import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import { abi } from "../../artifacts/contracts/AcornCredits.json";
import MarketplaceListSkeleton from "./MarketplaceListSkeleton";

import config from "../../Config";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { GET_CRUS_LIST } from "./CSVQuery";

const { Search } = Input;

const MyCRU = (props) => {
  const client = useApolloClient();

  const [tokenList, setTokenList] = useState([]);
  const [state, setState] = useState({
    first: 8,
    skip: 0,
    sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
    limit: 8,
    pageNumber: 1,
    searchQuery: "",
    total: 0,
    where: {},
  });

  const [contract, setContract] = useState(null);

  useEffect(() => {
    walletConnect();
    getTokens(
      state.skip,
      state.sortBy,
      state.first,
      state.searchQuery,
      state.where
    );
    // eslint-disable-next-line
  }, []);

  const contractAddress = config.CONTRACT_ADDRESS;

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value);
  };

  const onSearch = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));

    getTokens(state.skip, state.sortBy, state.first, value, state.where);
  };

  const debounceLoadData = useCallback(debounce(onSearch, 1000), []);

  const getTokens = (skip, sortBy, first, searchQuery, where) => {
    client
      .query({
        query: GET_CRUS_LIST,
        variables: { skip, sortBy, first, searchQuery, where },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        setTokenList(
          tokenList.length > 0
            ? tokenList.concat(result.data.getCRUs.rows)
            : result.data.getCRUs.rows
        );

        setState((prevState) => ({
          ...prevState,
          total: result.data.getCRUs.count,
          totalPage: Math.ceil(result.data.getCRUs.count / state.limit),
        }));
      })
      .catch((e) => {
        ErrorNotificationMsg("Error in get tokens.");
      });
  };

  const loadMoreTokens = async () => {
    const skipRecord = state.pageNumber * state.limit;

    await setState((prevState) => ({
      ...prevState,
      skip: skipRecord,
      sortBy: state.sortBy,
      pageNumber: state.pageNumber + 1,
    }));

    getTokens(
      skipRecord,
      state.sortBy,
      state.first,
      state.searchQuery,
      state.where
    );
  };

  const goToTokenDetail = (tokenData) => {
    props.history.push({
      pathname: "/cru-detail/",
      state: { tokenDetail: tokenData },
    });
  };

  return (
    <>
      <div className="pagename">My CRUs</div>
      <div
        className="content_wrapper dashboardPage"
        style={{ overflow: "visible" }}
      >
        <section className="gridwrP h11">
          <div className="heading">
            <Row gutter={{ xs: 0 }} align="middle">
              <Col flex="auto"></Col>
              <Col flex="auto" align="end">
                <Space>
                  <div className="searchhwrap">
                    <Search
                      placeholder="Search here"
                      onChange={searchCallbackDelayed}
                    />
                  </div>
                </Space>
              </Col>
            </Row>
          </div>

          <div className="cardwrp h11">
            <InfiniteScroll
              dataLength={tokenList.length}
              next={loadMoreTokens}
              hasMore={tokenList.length === state.total ? false : true}
              loader={<MarketplaceListSkeleton />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <Row gutter={32}>
                {tokenList.map((token, index) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={index}>
                    <div className="productCard">
                      <div onClick={() => goToTokenDetail(token)}>
                        <div className="imgDiv">
                          <img
                            src={token.metadata.image}
                            alt={"token-img" + index}
                          />
                          <div className={"outofstock " + token.status}>
                            {token.status}
                          </div>
                        </div>
                        <div className="descp">
                          <h3>{token.metadata.name}</h3>
                          <p>{token.metadata.description}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          </div>
        </section>
      </div>
    </>
  );
};

export default MyCRU;
