import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Card, Col, Row, Layout } from "antd";

import DashboardChart from "./DashboardChart";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { authUserData } from "../../utils/Helpers";

import { DASHBOARD_METRICS_QUERY } from "./DashboardQuery";

const { Content } = Layout;

const Dashboard = (props) => {
  const client = useApolloClient();
  const [state, setState] = useState({
    dashboardData: {},
    buyerStatusChartData: [],
    tokenStatusChartData: [],
  });

  useEffect(() => {
    if (authUserData()?.role !== "BUYER") {
      getDashboardData();
    } else if (authUserData()?.role === "BUYER") {
      props.history.push("marketplace");
    }
  }, []);

  const getDashboardData = () => {
    client
      .query({
        query: DASHBOARD_METRICS_QUERY,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        let pieChartColors = ["#EEC683", "#81DDC6", "#F45E58", "#50514F"];
        let buyerStatusChartData = [];
        let tokenStatusChartData = [];
        let dashboardData = {};

        result.data.getDashboardMetrics.buyerMetrics.forEach((element, i) => {
          buyerStatusChartData.push({
            y: element.count,
            label: element.isWhitelisted ? "Whitelisted" : "Not Whitelisted",
            color: pieChartColors[i],
          });
        });

        result.data.getDashboardMetrics.cruMetrics.map((element, i) => {
          tokenStatusChartData.push({
            y: element.count,
            label: element.status,
            color: pieChartColors[i],
          });
          dashboardData[element.status] = element.count;
        });

        setState({
          buyerStatusChartData,
          tokenStatusChartData,
          dashboardData,
          totalProjects: result.data.getDashboardMetrics.totalProjects,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  return (
    <>
      <div className="pagename">Dashboard</div>

      {authUserData()?.role === "BUYER" && (
        <div className="content_wrapper formPage">
          <section className="formWrapper">Welcome to dashboard</section>
        </div>
      )}

      {authUserData()?.role !== "BUYER" && (
        <div className="content_wrapper formPage">
          <Content>
            <div className="right_content_col">
              <div className="content_wraper dashboardPage">
                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, xl: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={6}>
                    <Card bordered={false}>
                      <Row>
                        <Col></Col>
                        <Col flex="auto">
                          <h3>
                            {state?.dashboardData?.ISSUED > 0
                              ? state.dashboardData.ISSUED
                              : 0}
                          </h3>
                          <h5>Issued CRUs</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card bordered={false}>
                      <Row>
                        <Col></Col>
                        <Col flex="auto">
                          <h3>
                            {state?.dashboardData?.RETIRED > 0
                              ? state.dashboardData.RETIRED
                              : 0}
                          </h3>
                          <h5>Retired CRUs</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          {/* <img
                          className="cardicon"
                          alt="icon"
                          src={totalrecord}
                        ></img> */}
                        </Col>
                        <Col flex="auto">
                          <h3>
                            {state?.dashboardData?.RESERVED > 0
                              ? state.dashboardData.RESERVED
                              : 0}
                          </h3>
                          <h5>Reserved CRUs</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={6}>
                    <Card bordered={false}>
                      <Row>
                        <Col></Col>
                        <Col flex="auto">
                          <h3>{state.totalProjects || 0}</h3>
                          <h5>Total Projects</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row gutter={{ xs: 16, sm: 16, md: 16, xl: 32 }}>
                  <Col xs={24} xl={10}>
                    <DashboardChart
                      title="Buyer Status"
                      typeChartData={state.buyerStatusChartData}
                    />
                  </Col>
                  <Col xs={24} xl={14}>
                    <DashboardChart
                      title="CRU Status"
                      typeChartData={state.tokenStatusChartData}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </div>
      )}
    </>
  );
};

export default Dashboard;
