import { gql } from "graphql-tag";

export const LOGIN_WITH_SIGNATURE = gql`
  mutation loginWithSignature(
    $publicKey: String!
    $signature: String!
    $message: String!
  ) {
    loginWithSignature(
      publicKey: $publicKey
      signature: $signature
      message: $message
    ) {
      token
      user {
        userId
        corporationName
        corporationId
        country
        role
        publicKey
        email
        isActive
        updatedAt
      }
    }
  }
`;


export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($activationToken: String!) {
    activateAccount(activationToken: $activationToken)
  }
`;
