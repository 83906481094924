import { useCallback, useEffect, useReducer } from "react";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router";
import { providers } from "ethers";
import Web3Modal from "web3modal";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { ellipseAddress, isAuthenticated } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { LOGIN_WITH_SIGNATURE } from "./AuthQuery";

// const providerOptions = {
//   walletconnect: {
//     package: WalletConnectProvider, // required
//     options: {
//       infuraId: INFURA_ID, // required
//     },
//   },
//   "custom-walletlink": {
//     display: {
//       logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
//       name: "Coinbase",
//       description: "Connect to Coinbase Wallet (not Coinbase App)",
//     },
//     options: {
//       appName: "Coinbase", // Your app name
//       networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
//       chainId: 1,
//     },
//     package: WalletLink,
//     connector: async (_, options) => {
//       const { appName, networkUrl, chainId } = options;
//       const walletLink = new WalletLink({
//         appName,
//       });
//       const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
//       await provider.enable();
//       return provider;
//     },
//   },
// };

let web3Modal;
if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true,
  });
}

const initialState = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
  signature: null,
  network: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_WEB3_PROVIDER":
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
        signature: action.signature,
        network: action.network,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.address,
      };
    case "SET_CHAIN_ID":
      return {
        ...state,
        chainId: action.chainId,
      };
    case "RESET_WEB3_PROVIDER":
      return initialState;
    default:
      throw new Error();
  }
}

export const MetaConnect = (props) => {
  const history = useHistory();
  const client = useApolloClient();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { provider, web3Provider, address } = state;

  const checkMetaMaskExtension = () => {
    if (!window.ethereum) {
      window.open("https://metamask.io/download.html", "_blank");
    }
  };

  const connect = useCallback(
    async function () {
      checkMetaMaskExtension();

      // This is the initial `provider` that is returned when
      // using web3Modal to connect. Can be MetaMask or WalletConnect.
      const provider = await web3Modal.connect();

      // We plug the initial `provider` into ethers.js and get back
      // a Web3Provider. This will add on methods from ethers.js and
      // event listeners such as `.on()` will be different.
      const web3Provider = new providers.Web3Provider(provider);

      const signer = web3Provider.getSigner();

      const address = await signer.getAddress();
      const network = await web3Provider.getNetwork();

      const message = [
        "Welcome to Marketplace. This site is requesting your signature to approve login authorization!",
        "Click to sign in and accept the terms and conditions (https://example.org/) of this app.",
        "This request will not trigger blockchain transaction or cost any gas fees.",
        `Wallet Address: ${address}`,
        `Timestamp: ${new Date().toISOString()}`,
        `Id: ${Math.random().toString(36).slice(-10)}`,
      ].join("\n\n");

      const signature = await signer.signMessage(message);

      client
        .mutate({
          mutation: LOGIN_WITH_SIGNATURE,
          variables: { publicKey: address.toLowerCase(), signature, message },
        })
        .then((res) => {
          dispatch({
            type: "SET_WEB3_PROVIDER",
            provider,
            web3Provider,
            address: address.toLowerCase(),
            chainId: network.chainId,
            signature,
            network,
          });

          localStorage.clear();
          localStorage.setItem("token", res.data.loginWithSignature.token);
          localStorage.setItem(
            "userData",
            JSON.stringify(res.data.loginWithSignature.user)
          );
          localStorage.setItem("address", address.toLowerCase());
          window.location.href = "/dashboard";
        })
        .catch((e) => {
          ErrorNotificationMsg("Error in login.");
        });
    },
    [history]
  );

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        await provider.disconnect();
      }
      dispatch({
        type: "RESET_WEB3_PROVIDER",
      });

      localStorage.clear();
      history.push("/");
    },
    [provider, history]
  );

  // Auto connect to the cached provider
  // useEffect(() => {
  //   if (web3Modal.cachedProvider) {
  //     connect();
  //   }
  // }, [connect]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        // eslint-disable-next-line no-console
        dispatch({
          type: "SET_ADDRESS",
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };

      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  //const chainData = chainId;

  return (
    <>
      {isAuthenticated() ? (
        <Button type="primary" htmlType="button" onClick={disconnect}>
          Disconnect
        </Button>
      ) : (
        <Button type="primary" htmlType="button" onClick={connect}>
          Connect to Wallet <RightOutlined />
        </Button>
      )}
    </>
  );
};

export default MetaConnect;
