import React, { useState, useEffect } from "react";
import { Col, Row, Collapse, Table, Space } from "antd";
import moment from "moment";

import config from "../../Config";
import PlaceOrderModal from "./PlaceOrderModal";
import {
  CamelCaseToSpaceLetter,
  ellipseAddress,
  authUserData,
  isCRUTokenRevealable,
} from "../../utils/Helpers";

const { Panel } = Collapse;

const MarketplaceDetail = (props) => {
  const [tokenDetail, setTokenDetail] = useState([]);
  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    if (props.location.state && props.location.state) {
      setTokenDetail(props.location.state.tokenDetail);
    } else {
      window.location.href = "/dashboard";
    }
    // eslint-disable-next-line
  }, []);

  function callback() {}

  const columns = [
    {
      title: "Transaction Hash",
      key: "transactionHash",
      render: (record) => (
        <a
          href={`https://mumbai.polygonscan.com/tx/${record.transactionHash}`}
          rel="noreferrer"
          target="_blank"
        >
          {record.transactionHash.slice(0, 20) + "..."}
        </a>
      ),
      sorter: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      width: "100",
      render: (res) => ellipseAddress(res),
    },
    {
      title: "Age",
      key: "updatedAt",
      render: (res) => moment(res.updatedAt).fromNow(),
      sorter: false,
    },
  ];

  return (
    <>
      <div className="pagename">
        Marketplace Detail -{" "}
        {isCRUTokenRevealable(tokenDetail) ? tokenDetail?.name : "Acorn Tokens"}{" "}
      </div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <div className="marketplaceDetail">
            <Row gutter={32}>
              <Col xs={24} sm={12} md={12} lg={12}>
                <div className="prodimg">
                  <img
                    src={
                      isCRUTokenRevealable(tokenDetail)
                        ? tokenDetail?.metadata?.image
                        : "https://img.icons8.com/fluency/48/000000/green-earth.png"
                    }
                    alt="token-img"
                  />
                  <div className={"outofstock " + tokenDetail.status}>
                    {tokenDetail.status}
                  </div>
                </div>
                <br />
                <Collapse onChange={callback}>
                  <Panel header="Properties" key="1" className="rightcl">
                    <div className="propertyCont">
                      {tokenDetail.metadata && (
                        <Row gutter={16}>
                          {Object.entries(
                            tokenDetail?.metadata?.properties
                          ).map(([key, value], i) => (
                            <Col xs={24} sm={12} md={8} lg={8}>
                              <div className="propcard">
                                {isCRUTokenRevealable(tokenDetail)
                                  ? CamelCaseToSpaceLetter(key)
                                  : "Sensitive"}
                                <h4>
                                  {isCRUTokenRevealable(tokenDetail)
                                    ? value
                                    : "Sensitive"}
                                </h4>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </div>
                  </Panel>
                </Collapse>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} className="rightcl">
                <h3>
                  <Space>
                    {isCRUTokenRevealable(tokenDetail)
                      ? tokenDetail?.name
                      : "Acorn Tokens"}
                    {authUserData()?.role === "BUYER" && (
                      <PlaceOrderModal
                        tokenData={tokenDetail}
                        history={props.history}
                      />
                    )}
                  </Space>
                </h3>

                <Row gutter={32}>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Token ID</h5>
                    <div className="descp">
                      <a
                        href={`https://mumbai.polygonscan.com/token/${contractAddress}?a=${tokenDetail?.tokenId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {tokenDetail?.tokenId}
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Token Standard</h5>
                    <div className="descp">ERC-1155</div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Blockchain</h5>
                    <div className="descp">Polygon Mumbai</div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Minted Qty</h5>
                    <div className="descp">{tokenDetail.quantity}</div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Available Qty</h5>
                    <div className="descp">{tokenDetail.availableQuantity}</div>
                  </Col>
                </Row>

                <h5>Description</h5>
                <div className="descp">
                  {isCRUTokenRevealable(tokenDetail)
                    ? tokenDetail?.metadata?.description
                    : "CRUs sequestered by project on acorn platform"}
                </div>
                <br />
                <Collapse onChange={callback}>
                  <Panel header="CRUs Listing" key="1">
                    <Table
                      columns={columns}
                      dataSource={
                        isCRUTokenRevealable(tokenDetail)
                          ? tokenDetail.crus
                          : []
                      }
                      pagination={false}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
};

export default MarketplaceDetail;
