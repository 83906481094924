import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table } from "antd";

const BlockExplorer = (props) => {
  const [apiLoading, setApiLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);

  const [state, setState] = useState({
    first: 20,
    skip: 0,
    sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
    limit: 20,
    pageNumber: 1,
    searchQuery: "",
    total: 0,
    where: {},
  });

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = () => {
    setApiLoading(true);
    let address = localStorage.getItem("address");

    let url =
      "https://api-testnet.polygonscan.com/api?module=account&action=txlist&address=" +
      address +
      "&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=K4STG2ZYXTGEB8XER4YE7JKRW1BCKX47ZE";

    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setTransactionList(json.result);
        setApiLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {};

  const columns = [
    {
      title: "Txn Hash",
      render: (record) => (
        <a
          href={`https://mumbai.polygonscan.com/tx/${record.hash}`}
          rel="noreferrer"
          target="_blank"
        >
          {record.hash}
        </a>
      ),
      key: "hash",
      sorter: false,
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Block",
      dataIndex: "blockNumber",
      key: "blockNumber",
      sorter: false,
      width: "10%",
    },
    {
      title: "Age",
      key: "timeStamp",
      render: (record) => moment.unix(record.timeStamp).fromNow(),
      sorter: false,
      width: "10%",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      sorter: false,
      ellipsis: true,
      width: "20%",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      sorter: false,
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Value",
      key: "value",
      render: (record) => record.value / 1000000000000000000 + " MATIC",
      sorter: false,
      width: "10%",
    },
  ];

  return (
    <>
      <div className="pagename">Block Explorer</div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <Table
            className="table_grid"
            columns={columns}
            rowKey={(record) => record["corporationId"]}
            dataSource={transactionList}
            scroll={{ x: 970 }}
            loading={apiLoading}
            pagination={{
              defaultCurrent: state.pageNumber,
              defaultPageSize: state.limit,
              total: state.total,
              size: "small",
            }}
            onChange={handleTableChange}
          />
        </section>
      </div>
    </>
  );
};

export default BlockExplorer;
