import { gql } from "graphql-tag";

export const IMPORT_CRUS_FILE = gql`
  mutation importCRUsFile($file: Upload!, $currentTokenId: Int!) {
    importCRUsFile(file: $file, currentTokenId: $currentTokenId)
  }
`;

export const IMPORT_MINTED_CRUS = gql`
  mutation importMintedCRUs(
    $tokenIds: [Float!]!
    $amounts: [Int!]!
    $tokenURIs: [String!]!
    $metadatas: [Json!]!
    $transactionHash: String!
  ) {
    importMintedCRUs(
      records: {
        tokenIds: $tokenIds
        amounts: $amounts
        tokenURIs: $tokenURIs
        metadatas: $metadatas
      }
      transactionHash: $transactionHash
    )
  }
`;

export const GET_CRUS_LIST = gql`
  query getCRUs(
    $first: Int
    $skip: Int
    $sortBy: sortInput
    $where: CRUsWhereInput
    $searchQuery: String
  ) {
    getCRUs(
      first: $first
      skip: $skip
      sortBy: $sortBy
      where: $where
      searchQuery: $searchQuery
    ) {
      count
      rows {
        tokenId
        quantity
        status
        orderId
        buyer
        metadata
        metadataURI
        status
        transactionHash
      }
    }
  }
`;

export const GET_CRU_PROJECTS_LIST = gql`
  query getCRUProjects(
    $first: Int
    $skip: Int
    $sortBy: sortInput
    $where: CRUProjectsWhereInput
    $searchQuery: String
  ) {
    getCRUProjects(
      first: $first
      skip: $skip
      sortBy: $sortBy
      where: $where
      searchQuery: $searchQuery
    ) {
      count
      rows {
        projectId
        tokenId
        name
        description
        issuer
        assetId
        verificationStandard
        quantity
        availableQuantity
        crus {
          cruBatchId
          tokenId
          transactionHash
          quantity
          status
          buyer
          orderId
          createdAt
          updatedAt
        }
        metadata
        metadataURI
        status
      }
    }
  }
`;
