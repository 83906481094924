import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Row, Col, Form, Divider, Button, Upload, Space } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

import { ethers, providers } from "ethers";
import { abi } from "../../artifacts/contracts/AcornCredits.json";
import Web3Modal from "web3modal";

import config from "../../Config";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
  ShowErrorMessages,
} from "../../utils/NotificationHelper";
import { IMPORT_CRUS_FILE, IMPORT_MINTED_CRUS } from "./CSVQuery";
import SampleCSV from "../../images/metadata_header.csv";

const ImportCSV = (props) => {
  const { history } = props;
  const client = useApolloClient();
  const initUploadData = {
    file: null,
  };

  const [uploadData, setUploadData] = useState(initUploadData);
  const [contract, setContract] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    walletConnect();
  }, []);

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const uploadCSVFile = ({ file, onSuccess, onError }) => {
    setUploadData({ ...uploadData, file });
  };

  const handleSubmit = async () => {
    if (uploadData.file === null) {
      ErrorNotificationMsg("Please upload a valid CSV file!");
      return false;
    }

    setBtnLoading(true);

    const token = await contract.currentTokenId();

    if (token === null || token === undefined || token === "") {
      ErrorNotificationMsg("Error in fetching token.");
      return false;
    }

    client
      .mutate({
        mutation: IMPORT_CRUS_FILE,
        variables: {
          file: uploadData.file,
          currentTokenId: token.toNumber(),
        },
      })
      .then((res) => {
        if (
          res?.data?.importCRUsFile?.errors &&
          res.data.importCRUsFile.errors.length !== 0
        ) {
          ShowErrorMessages(res.data.importCRUsFile.errors);
          setBtnLoading(false);
          return;
        }

        if (res.data.importCRUsFile.tokenIds.length === 0) {
          ErrorNotificationMsg("No new tokens for mint.");
          setBtnLoading(false);
          return;
        }

        const cruFileRes = {
          tokenIds: res.data.importCRUsFile.tokenIds,
          amounts: res.data.importCRUsFile.tokenAmounts,
          tokenURIs: res.data.importCRUsFile.tokenURIs,
          metadatas: res.data.importCRUsFile.metadatas,
        };

        contract
          .mintCRUProjectBatches(
            res.data.importCRUsFile.tokenIds,
            res.data.importCRUsFile.tokenAmounts,
            res.data.importCRUsFile.tokenURIs
          )
          .then((tx) => {
            tx.wait().then((res) => {
              client
                .mutate({
                  mutation: IMPORT_MINTED_CRUS,
                  variables: { ...cruFileRes, transactionHash: tx.hash },
                })
                .then((resp) => {
                  SuccessNotificationMsg(
                    "Success",
                    cruFileRes.tokenIds.length + " Token minted successfully!"
                  );
                  setBtnLoading(false);
                  history.push("/marketplace");
                })
                .catch((e) => {
                  ErrorNotificationMsg("Erron in token mint.");
                  setBtnLoading(false);
                });
            });
          })
          .catch((e) => {
            ErrorNotificationMsg(e.data.message);
            setBtnLoading(false);
          });
      })
      .catch((e) => {
        ErrorNotificationMsg(
          e.message !== "" && e.message !== undefined
            ? e.message
            : "Erron in file upload."
        );
        setBtnLoading(false);
      });
  };

  const uploadProps = {
    multiple: false,
    listType: false,
    progress: false,
    accept: ".csv",
    showUploadList: false,
  };

  return (
    <>
      <div className="pagename">CSV Import</div>
      <div className="content_wrapper formPage">
        <div className="formWrapper">
          <Form>
            <Row gutter={[15]}>
              <Col xs={24} sm={24} lg={24}>
                <Space align="start">
                  <label className="lbl">CSV Upload *</label>
                  <Form.Item className="bulwrp" style={{ marginBottom: 0 }}>
                    <Upload
                      {...uploadProps}
                      customRequest={uploadCSVFile}
                      accept=".csv"
                      className="bulk-upload-file"
                    >
                      <Button size="small" icon={<UploadOutlined />}>
                        Click to Upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <a
                    href={SampleCSV}
                    target="_blank"
                    rel="noreferrer"
                    className="link csvlink"
                  >
                    SAMPLE CSV FILE
                  </a>
                </Space>
              </Col>
              <Col xs={24} sm={24} lg={24}>
                <div className="filewrap">
                  {uploadData.file && (
                    <Space align="start">
                      <h4>{uploadData.file.name}</h4>
                      <Button
                        size="small"
                        type="link"
                        onClick={() => setUploadData(initUploadData)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Space>
                  )}
                </div>
              </Col>
            </Row>

            <Divider />
            <Row type="flex" justify="end">
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="button"
                    onClick={() => setUploadData(initUploadData)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={btnLoading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ImportCSV;
