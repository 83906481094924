import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Space } from "antd";
import { useApolloClient } from "@apollo/client";

import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import { abi } from "../../artifacts/contracts/AcornCredits.json";

import config from "../../Config";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { TRANSFER_CRU, CONFIRM_PAYMENT } from "./OrderQuery";

const NotificationAction = (props) => {
  const client = useApolloClient();
  const [contract, setContract] = useState(null);
  const [confirmPaymentApiLoading, setConfirmPaymentApiLoading] =
    useState(false);
  const [transferCRUApiLoading, setTransferCRUApiLoading] = useState(false);
  const [showPaymentButton, setShowPaymentButton] = useState(
    props.data.isPaymentRecieved ? false : true
  );
  const [showTransferCRUButton, setShowTransferCRUButton] = useState(
    props.data.isPaymentRecieved ? true : false
  );

  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    walletConnect();
  }, []);

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const confirmPayment = ({ orderId }) => {
    setConfirmPaymentApiLoading(true);

    client
      .mutate({
        mutation: CONFIRM_PAYMENT,
        variables: { orderId },
      })
      .then(() => {
        setConfirmPaymentApiLoading(false);
        SuccessNotificationMsg("Payment confirmed successfully.");
        setShowTransferCRUButton(true);
        setShowPaymentButton(false);
      })
      .catch((e) => {
        ErrorNotificationMsg("Failed to confirmed payment.");
        setConfirmPaymentApiLoading(false);
      });
  };

  const transferCRU = ({ buyer, tokenId, quantity, orderId }) => {
    setTransferCRUApiLoading(true);
    const from = localStorage.getItem("address");

    contract
      .safeTransferFrom(from, buyer, tokenId, quantity, "0x")
      .then((tx) => {
        tx.wait().then((res) => {
          client
            .mutate({
              mutation: TRANSFER_CRU,
              variables: { orderId, transactionHash: tx.hash },
            })
            .then(() => {
              setTransferCRUApiLoading(false);
              SuccessNotificationMsg("CRU's transferred successfully.");
              setShowTransferCRUButton(false);
              setShowPaymentButton(false);
            })
            .catch((e) => {
              ErrorNotificationMsg("Failed to transfer CRU's");
              setTransferCRUApiLoading(false);
            });
        });
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message);
        setTransferCRUApiLoading(false);
      });
  };

  return (
    <>
      <Space>
        <div className="notification-buttons">
          {showPaymentButton && (
            <Button
              loading={confirmPaymentApiLoading}
              onClick={() => confirmPayment(props.data)}
              size="small"
            >
              Confirm Payment
            </Button>
          )}

          {showTransferCRUButton && (
            <Button
              loading={transferCRUApiLoading}
              onClick={() => transferCRU(props.data)}
              size="small"
            >
              Transfer CRU
            </Button>
          )}

          <span>{moment(props.data.createdAt).fromNow()}</span>
        </div>
      </Space>
    </>
  );
};

export default NotificationAction;
