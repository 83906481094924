import React from "react";
import CanvasJSReact from "../../charts/canvasjs.react";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DashboardChart = (props) => {
  const pie1 = {
    animationEnabled: true,
    title: {
      text: "",
      horizontalAlign: "",
    },
    data: [
      {
        type: "doughnut",
        startAngle: 200,
        innerRadius: 40,
        indexLabelFontSize: 14,
        indexLabel: "{label} - {y}",
        toolTipContent: "<b>{label}:</b> {y}",
        dataPoints: props.typeChartData,
      },
    ],
  };

  return (
    <div className="chart-wrapper chart_mainwrap">
      <div className="headingrow">{props.title}</div>
      <CanvasJSChart options={pie1} />
    </div>
  );
};

export default DashboardChart;
