import React, { useState, useEffect } from "react";

import { useApolloClient } from "@apollo/client";
import { Dropdown, List } from "antd";
import { BellOutlined } from "@ant-design/icons";

import { authUserData, ellipseAddress } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { NOTIFICATION_LIST_SUBSCRIPTION, ORDER_LIST } from "./OrderQuery";
import NotificationAction from "./NotificationAction";

const NotificationList = () => {
  const client = useApolloClient();
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    getNotifications();
    getRecentOrders();
  }, []);

  const getRecentOrders = () => {
    client
      .query({
        query: ORDER_LIST,
        variables: {
          skip: 0,
          first: 5,
          sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
          where: { status: "RESERVED" },
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        setNotificationList((prevState) => [
          ...result.data.getCRUOrders.rows,
          ...prevState,
        ]);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error in get recent orders.");
      });
  };

  const getNotifications = () => {
    client
      .subscribe({
        query: NOTIFICATION_LIST_SUBSCRIPTION,
        variables: { userId: authUserData().userId },
        fetchPolicy: "network-only",
      })
      .subscribe({
        next: ({ data }) => {
          setNotificationList((prevState) => [data.notification, ...prevState]);
          SuccessNotificationMsg(
            data.notification.event.replaceAll("_", " "),
            data.notification.message
          );
        },
      });
  };

  const notify = (
    <List
      itemLayout="horizontal"
      dataSource={notificationList}
      renderItem={(item) => (
        <List.Item actions={[<NotificationAction data={item} />]}>
          <List.Item.Meta
            title="CRU ORDER PLACED"
            description={`CRU order placed by ${ellipseAddress(item.buyer)} `}
          />
        </List.Item>
      )}
    />
  );

  return (
    <>
      <Dropdown
        overlay={notify}
        trigger={["click"]}
        placement="topRight"
        getPopupContainer={() => document.getElementById("coskun")}
      >
        <div className="notify" onClick={(e) => e.preventDefault()} id="coskun">
          <BellOutlined />
          {notificationList.length > 0 && (
            <span className="count">{notificationList.length}</span>
          )}
        </div>
      </Dropdown>
    </>
  );
};

export default NotificationList;
