import React from "react";
import "./Home.scss";

const AboutUs = () => {
  return (
    <div className="frontContent">
      <div className="container">
        <div className="pagename">About Us</div>
        <div className="contentbody">
          Smallholder farmers and their communities are often on the receiving
          end of climate change – but they can also play an important role in
          reversing its effects. NFT is developing NFT to open the carbon
          market up to smallholder farmers using tried and true agroforestry
          practices and modern, enabling technologies.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
