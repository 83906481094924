const config = {
  ENV: process.env.REACT_APP_APP_ENV,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  APP_URL: process.env.REACT_APP_APP_URL,
  API_URL: process.env.REACT_APP_API_URL,
  API_WEBSOCKET_URL: process.env.REACT_APP_API_WEBSOCKET_URL,
  CONTRACT_ADDRESS: "0xD1469c441F921BD8b16008001bb4603bc7a2a31b",
};

export default config;
