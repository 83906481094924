import { gql } from "graphql-tag";

export const ORDER_LIST = gql`
  query getCRUOrders(
    $first: Int
    $skip: Int
    $sortBy: sortInput
    $where: CRUOrdersWhereInput
    $searchQuery: String
  ) {
    getCRUOrders(
      first: $first
      skip: $skip
      sortBy: $sortBy
      where : $where
      searchQuery: $searchQuery
    ) {
      count
      rows {
        orderId
        tokenId
        buyer
        quantity
        orderPrice
        priceCurrency
        status
        isPaymentRecieved
        createdAt
      }
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPaymentReceived($orderId: String!) {
    confirmPaymentReceived(orderId: $orderId)
  }
`;

export const TRANSFER_CRU = gql`
  mutation transferCRUs($orderId: String!, $transactionHash: String!) {
    transferCRUs(orderId: $orderId, transactionHash: $transactionHash)
  }
`;

export const ORDER_CRU = gql`
  mutation orderCRUs(
    $tokenId: Int!
    $quantity: Int!
    $transactionHash: String!
  ) {
    orderCRUs(
      tokenId: $tokenId
      quantity: $quantity
      transactionHash: $transactionHash
    )
  }
`;

export const NOTIFICATION_LIST_SUBSCRIPTION = gql`
  subscription notification($userId: String!) {
    notification(userId: $userId)
  }
`;

export const CRU_LOG_LIST = gql`
  query getCRUTransactionLogs(
    $first: Int
    $skip: Int
    $sortBy: sortInput
    $searchQuery: String
    $fromDate: Date
    $toDate: Date
  ) {
    getCRUTransactionLogs(
      first: $first
      skip: $skip
      sortBy: $sortBy
      searchQuery: $searchQuery
      fromDate: $fromDate
      toDate: $toDate
    ) {
      count
      rows {
        transactionHash
        transactionType
        cruQuantity
        tokenIds
        cruStatus
        txCreatedBy {
          corporationName
          publicKey
        }
        createdAt
        updatedAt
      }
    }
  }
`;
