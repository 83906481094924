import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  ShopOutlined,
  AuditOutlined,
  UserAddOutlined,
  OrderedListOutlined,
  TagOutlined,
  BlockOutlined,
  ImportOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import { authUserData } from "../../utils/Helpers";
import Logow from "../../images/logow.png";

const CustomTrigger = () => <MenuFoldOutlined />;
const { Sider } = Layout;

const Sidebar = (props) => {
  return (
    <Sider
      width={250}
      breakpoint="lg"
      collapsedWidth={0}
      collapsible
      trigger={<CustomTrigger />}
      style={{
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Link to="" className="logo">
        <img src={Logow} alt="" />
      </Link>
      <div className="profilebar">Hello, {authUserData()?.corporationName}</div>
      <div className="sidebar_menu">
        <Menu
          defaultSelectedKeys={["/dashboard"]}
          defaultOpenKeys={["connected-application"]}
          mode="inline"
        >
          {authUserData()?.role === "BUYER" && (
            <>
              <Menu.Item key="/marketplace" icon={<ShopOutlined />}>
                <Link to="/marketplace">Marketplace</Link>
              </Menu.Item>
              <Menu.Item key="/my-crus" icon={<TagOutlined />}>
                <Link to="/my-crus">My CRUs</Link>
              </Menu.Item>
              <Menu.Item key="/manage-order" icon={<OrderedListOutlined />}>
                <Link to="/manage-order">Orders List</Link>
              </Menu.Item>
              <Menu.Item key="/block-explorer" icon={<BlockOutlined />}>
                <Link to="/block-explorer">Block Explorer</Link>
              </Menu.Item>
            </>
          )}

          {authUserData()?.role === "PROJECT_DEVELOPER" && (
            <>
              <Menu.Item key="/dashboard" icon={<AppstoreOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="/marketplace" icon={<ShopOutlined />}>
                <Link to="/marketplace">Marketplace</Link>
              </Menu.Item>
              <Menu.Item key="/import-csv" icon={<ImportOutlined />}>
                <Link to="/import-csv">CSV Import </Link>
              </Menu.Item>
              <Menu.Item key="/cru-registry" icon={<HistoryOutlined />}>
                <Link to="/cru-registry">CRU Registry</Link>
              </Menu.Item>
              <Menu.Item key="/block-explorer" icon={<BlockOutlined />}>
                <Link to="/block-explorer">Block Explorer</Link>
              </Menu.Item>
            </>
          )}

          {authUserData()?.role === "PLATFORM_OPERATOR" && (
            <>
              <Menu.Item key="/dashboard" icon={<AppstoreOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="/marketplace" icon={<ShopOutlined />}>
                <Link to="/marketplace">Marketplace</Link>
              </Menu.Item>
              <Menu.Item key="/add-buyer" icon={<UserAddOutlined />}>
                <Link to="/add-buyer">Create Buyer</Link>
              </Menu.Item>
              <Menu.Item key="/manage-buyer" icon={<AuditOutlined />}>
                <Link to="/manage-buyer">Manage Buyer</Link>
              </Menu.Item>
              <Menu.Item key="/manage-order" icon={<OrderedListOutlined />}>
                <Link to="/manage-order">Orders List</Link>
              </Menu.Item>
              <Menu.Item key="/cru-registry" icon={<HistoryOutlined />}>
                <Link to="/cru-registry">CRU Registry</Link>
              </Menu.Item>
              <Menu.Item key="/block-explorer" icon={<BlockOutlined />}>
                <Link to="/block-explorer">Block Explorer</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
