import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useApolloClient } from "@apollo/client";
import { debounce } from "lodash";
import { Table, Col, Row, Input, Space, Button, Tag, DatePicker } from "antd";

import { authUserData } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { GET_CRUS_LIST } from "../csvImport/CSVQuery";

const { Search } = Input;
const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const CRURegistry = (props) => {
  const client = useApolloClient();
  const [apiLoading, setApiLoading] = useState(false);
  const [logList, setLogList] = useState([]);
  const initialState = {
    first: 20,
    skip: 0,
    sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
    limit: 20,
    pageNumber: 1,
    searchQuery: "",
    total: 0,
    where: {},
    startDate: null,
    endDate: null,
  };
  const [state, setState] = useState(initialState);
  useEffect(() => {
    getCRUS(
      state.skip,
      state.sortBy,
      state.first,
      state.searchQuery,
      state.where,
      state.startDate,
      state.endDate
    );
  }, []);

  const handleDatepickerChange = (dates, dateStrings) => {
    setState((prevState) => ({
      ...prevState,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    }));
  };

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value);
  };

  const onSearchDateRange = () => {
    getCRUS(
      state.skip,
      state.sortBy,
      state.first,
      state.searchQuery,
      state.where,
      state.startDate,
      state.endDate
    );
  };

  const handleReset = () => {
    window.location.reload();
  };

  const onSearch = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));

    getCRUS(
      state.skip,
      state.sortBy,
      state.first,
      value,
      state.where,
      state.startDate,
      state.endDate
    );
  };

  const debounceLoadData = useCallback(debounce(onSearch, 1000), []);

  const getCRUS = (
    skip,
    sortBy,
    first,
    searchQuery,
    where,
    startDate,
    endDate
  ) => {
    let fromDate = startDate
      ? moment(startDate, "DD-MM-YYYY")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : null;
    let toDate = endDate
      ? moment(endDate, "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : null;

    setApiLoading(true);
    client
      .query({
        query: GET_CRUS_LIST,
        variables: {
          skip,
          sortBy,
          first,
          searchQuery,
          where,
          fromDate,
          toDate,
        },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        setLogList(result.data.getCRUs.rows);
        setState((prevState) => ({
          ...prevState,
          total: result.data.getCRUs.count,
        }));
        setApiLoading(false);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error in list CRUs.");
        setApiLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    if (pagination.current === 1) {
      await setState((prevState) => ({
        ...prevState,
        skip: 0,
        sortBy: sortBy,
      }));
      getCRUS(
        0,
        sortBy,
        state.first,
        state.searchQuery,
        state.where,
        state.startDate,
        state.endDate
      );
    } else {
      const skipRecord = (pagination.current - 1) * state.limit;
      await setState((prevState) => ({
        ...prevState,
        skip: skipRecord,
        sortBy: sortBy,
        pageNumber: pagination.current,
      }));
      getCRUS(
        skipRecord,
        sortBy,
        state.first,
        state.searchQuery,
        state.where,
        state.startDate,
        state.endDate
      );
    }
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "DESC" };

    if (sortField === "status" && sortOrder === "ascend") {
      sortingEnumKey = {
        sortingField: "status",
        sortingOrder: "ASC",
      };
    }
    if (sortField === "status" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "status",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "transactionHash" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "transactionHash", sortingOrder: "ASC" };
    }
    if (sortField === "transactionHash" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "transactionHash",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "tokenId" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "tokenId", sortingOrder: "ASC" };
    }
    if (sortField === "tokenId" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "tokenId", sortingOrder: "DESC" };
    }

    if (sortField === "quantity" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "quantity", sortingOrder: "ASC" };
    }
    if (sortField === "quantity" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "quantity", sortingOrder: "DESC" };
    }

    if (sortField === "buyer" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "buyer", sortingOrder: "ASC" };
    }
    if (sortField === "buyer" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "buyer", sortingOrder: "DESC" };
    }

    if (sortField === "createdAt" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "createdAt", sortingOrder: "ASC" };
    }
    if (sortField === "createdAt" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "createdAt", sortingOrder: "DESC" };
    }

    return sortingEnumKey;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "RESERVED":
        return "geekblue";
      case "SETTLED":
        return "green";
      default:
        return "volcano";
    }
  };

  const columns = [
    {
      title: "Transaction Hash",
      key: "transactionHash",
      render: (record) => (
        <a
          href={`https://mumbai.polygonscan.com/tx/${record.transactionHash}`}
          rel="noreferrer"
          target="_blank"
        >
          {record.transactionHash.slice(0, 20) + "..."}
        </a>
      ),
      sorter: true,
    },
    {
      title: "issuanceDate",
      key: "issuanceDate",
      sorter: false,
      render: (record) =>
        record.metadata.properties?.issuanceDate
          ? moment(
              record.metadata.properties.issuanceDate,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : "",
    },
    {
      title: "Project Name",
      key: "project_name",
      sorter: false,
      render: (record) => record.metadata.name,
    },
    {
      title: "Project Description",
      key: "project_desc",
      sorter: false,
      render: (record) => record.metadata.description,
    },
    {
      title: "Token Id",
      dataIndex: "tokenId",
      key: "tokenId",
      sorter: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: true,
    },
    {
      title: "Status",
      key: "status",
      render: (record) => (
        <Tag color={getStatusColor(record.status)} key={record.status}>
          {record.status}
        </Tag>
      ),
      sorter: true,
    },

    {
      title: "Buyer",
      key: "buyer",
      render: (record) =>
        record.buyer !== "None" ? record.buyer.slice(0, 20) + "..." : "---",
      sorter: true,
    },
    {
      title: "issuer",
      key: "issuer",
      sorter: false,
      render: (record) => record.metadata.properties.issuer,
    },
    {
      title: "method",
      key: "method",
      sorter: false,
      render: (record) => record.metadata.properties.method,
    },
    {
      title: "Asset Id",
      key: "assetId",
      sorter: false,
      render: (record) => record.metadata.properties.assetId,
    },
    {
      title: "Leakage",
      key: "leakage",
      sorter: false,
      render: (record) => record.metadata.properties.leakage,
    },
    {
      title: "Vintage",
      key: "vintage",
      sorter: false,
      render: (record) => record.metadata.properties.vintage,
    },
    {
      title: "Additionality",
      key: "additionality",
      sorter: false,
      render: (record) => record.metadata.properties.additionality,
    },
    {
      title: "Classification Category",
      key: "classificationCategory",
      sorter: false,
      render: (record) => record.metadata.properties.classificationCategory,
    },
    {
      title: "CO Benefits",
      key: "coBenefits",
      sorter: false,
      render: (record) => record.metadata.properties.coBenefits,
    },
    {
      title: "Corresponding Adjustment",
      key: "correspondingAdjustment",
      sorter: false,
      render: (record) => record.metadata.properties.correspondingAdjustment,
    },
    {
      title: "Determined Value",
      key: "determinedValue",
      sorter: false,
      render: (record) => record.metadata.properties.determinedValue,
    },
    {
      title: "Durability",
      key: "durability",
      sorter: false,
      render: (record) => record.metadata.properties.durability,
    },
    {
      title: "Issuer",
      key: "issuer",
      sorter: false,
      render: (record) => record.metadata.properties.issuer,
    },
    {
      title: "Verification Standard",
      key: "verificationStandard",
      sorter: false,
      render: (record) => record.metadata.properties.verificationStandard,
    },
  ];

  return (
    <>
      <div className="pagename">CRU Registry</div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <div className="heading">
            <Row gutter={{ xs: 0 }} align="middle">
              <Col flex="auto" className="datewrap"></Col>
              <Col flex="auto" align="end">
                <div className="searchhwrap">
                  <Search
                    placeholder="Search by status, buyer"
                    onChange={searchCallbackDelayed}
                    style={{ width: "250px" }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Table
            className="table_grid"
            columns={
              authUserData()?.role === "PLATFORM_OPERATOR"
                ? columns
                : columns.filter((col) => col.key !== "action")
            }
            rowKey={(record) => record["orderId"]}
            dataSource={logList}
            scroll={{ x: 970 }}
            loading={apiLoading}
            pagination={{
              defaultCurrent: state.pageNumber,
              defaultPageSize: state.limit,
              total: state.total,
              size: "small",
            }}
            onChange={handleTableChange}
          />
        </section>
      </div>
    </>
  );
};

export default CRURegistry;
