import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { ACTIVATE_ACCOUNT } from "./AuthQuery";

const ActivateAccount = (props) => {
  const client = useApolloClient();

  const [state] = useState({
    activationToken: props.match.params.activationToken,
  });
  const [responseMsg, setResponseMsg] = useState("Wait, We are checking!");
  const [apiLoading, setApiLoading] = useState(false);

  useEffect(() => {
    callActivateAccount();
    // eslint-disable-next-line
  }, []);

  const callActivateAccount = () => {
    setApiLoading(true);
    client
      .mutate({
        mutation: ACTIVATE_ACCOUNT,
        variables: { ...state },
      })
      .then((result) => {
        setApiLoading(false);
        setResponseMsg(
          "Your account activated successfully!"
        );
        setTimeout(() => {
          props.history.push("/");
        }, 4000);
      })
      .catch((e) => {
        setApiLoading(false);
        setResponseMsg("Requested token is invalid or link is expired!");
        setTimeout(() => {
          props.history.push("/");
        }, 4000);
      });
  };

  return (
    <>
      <div className="lgoin-wrap authpage" style={{ marginTop: "100px" }}>
        <div className="inside">
          <div className="pagename">
            <div className="activateaccount">
              <h5>Activate Account</h5>
              <div className="activation-note">
                {apiLoading && (
                  <div class="loader">
                    <div class="box"></div>
                    <div class="box"></div>
                  </div>
                )}
                <div className="msg">{responseMsg}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivateAccount;
