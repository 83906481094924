import React from "react";
import "./Home.scss";

const Token = () => {
  return (
    <div className="frontContent">
      <div className="container">
        <div className="pagename">Token</div>
        <div className="contentbody">content goes here....</div>
      </div>
    </div>
  );
};

export default Token;
