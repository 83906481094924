export const validateToken = (token) => {
  if (!token) {
    return false;
  } else {
    return true;
  }
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/";
};

export const isAuthenticated = () => {
  const isStored = localStorage.getItem("token");
  return !isStored ? false : true;
};

export const authUserData = () => {
  let authData = localStorage.getItem("userData");
  return JSON.parse(authData);
};

export const redirectIfNotLoggedIn = () => {
  let authData = authUserData();
  if (authData === null) {
    window.location.href = "/login";
  }
};

export const redirectIfLoggedIn = () => {
  let authData = authUserData();
  if (authData !== null && authData !== undefined) {
    if (authData.sub !== "") {
      window.location.href = "/dashboard";
    }
  }
};

export function ellipseAddress(address = "", width = 10) {
  if (!address || address === "None") {
    return "None";
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export const CamelCaseToSpaceLetter = (inputString) => {
  let result = inputString.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isCRUTokenRevealable = (token) => {
  if (authUserData()?.role !== "BUYER") return true;
  return token?.crus?.some(({ buyer }) => buyer === authUserData()?.publicKey);
};
