import { gql } from "graphql-tag";

export const BUYER_LIST = gql`
  query getPlatformBuyers(
    $first: Int
    $skip: Int
    $sortBy: sortInput
    $searchQuery: String
  ) {
    getPlatformBuyers(
      first: $first
      skip: $skip
      sortBy: $sortBy
      searchQuery: $searchQuery
    ) {
      count
      rows {
        buyerId
        corporationName
        corporationId
        country
        email
        publicKey
        isActive
        isWhitelisted
        updatedAt
      }
    }
  }
`;

export const ADD_BUYER = gql`
  mutation registerBuyer(
    $corporationName: String!
    $corporationId: String
    $country: String
    $email: String!
    $publicKey: String!
    $isWhitelisted: Boolean!
  ) {
    registerBuyer(
      data: {
        corporationName: $corporationName
        corporationId: $corporationId
        country: $country
        email: $email
        publicKey: $publicKey
        isWhitelisted: $isWhitelisted
      }
    )
  }
`;

export const UPDATE_BUYER = gql`
  mutation updateBuyer(
    $corporationName: String
    $corporationId: String
    $country: String
    $isWhitelisted: Boolean
    $isActive: Boolean
    $publicKey: String!
  ) {
    updateBuyer(
      publicKey: $publicKey
      data: {
        corporationName: $corporationName
        corporationId: $corporationId
        country: $country
        isWhitelisted: $isWhitelisted
        isActive: $isActive
      }
    )
  }
`;

export const DELETE_BUYER = gql`
  mutation deleteBuyer($publicKey: String!) {
    deleteBuyer(publicKey: $publicKey)
  }
`;
