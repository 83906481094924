import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { debounce } from "lodash";
import { Table, Button, Col, Row, Input, Space, Popconfirm, Empty } from "antd";

import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import { abi } from "../../artifacts/contracts/AcornCredits.json";

import config from "../../Config";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { BUYER_LIST, DELETE_BUYER } from "./BuyerQuery";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

const { Search } = Input;

const BuyerList = (props) => {
  const client = useApolloClient();
  const [apiLoading, setApiLoading] = useState(false);
  const [buyerList, setBuyerList] = useState([]);
  const [state, setState] = useState({
    first: 20,
    skip: 0,
    sortBy: { sortingField: "updatedAt", sortingOrder: "DESC" },
    limit: 20,
    pageNumber: 1,
    searchQuery: "",
    total: 0,
    where: {},
  });
  const [contract, setContract] = useState(null);

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value);
  };

  const onSearch = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));

    getBuyers(state.skip, state.sortBy, state.first, value, state.where);
  };

  const debounceLoadData = useCallback(debounce(onSearch, 1000), []);

  const getBuyers = (skip, sortBy, first, searchQuery, where) => {
    setApiLoading(true);
    client
      .query({
        query: BUYER_LIST,
        variables: { skip, sortBy, first, searchQuery, where },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        setBuyerList(result.data.getPlatformBuyers.rows);
        setState((prevState) => ({
          ...prevState,
          total: result.data.getPlatformBuyers.count,
        }));
        setApiLoading(false);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error in list buyer.");
        setApiLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    if (pagination.current === 1) {
      await setState((prevState) => ({
        ...prevState,
        skip: 0,
        sortBy: sortBy,
      }));
      getBuyers(0, sortBy, state.first, state.searchQuery, state.where);
    } else {
      const skipRecord = (pagination.current - 1) * state.limit;
      await setState((prevState) => ({
        ...prevState,
        skip: skipRecord,
        sortBy: sortBy,
        pageNumber: pagination.current,
      }));
      getBuyers(
        skipRecord,
        sortBy,
        state.first,
        state.searchQuery,
        state.where
      );
    }
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "DESC" };

    if (sortField === "corporationName" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "corporationName", sortingOrder: "ASC" };
    }
    if (sortField === "corporationName" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "corporationName",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = {
        sortingField: "email",
        sortingOrder: "ASC",
      };
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = {
        sortingField: "email",
        sortingOrder: "DESC",
      };
    }

    if (sortField === "publicKey" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "publicKey", sortingOrder: "ASC" };
    }
    if (sortField === "publicKey" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "publicKey", sortingOrder: "DESC" };
    }

    if (sortField === "corporationId" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "corporationId", sortingOrder: "ASC" };
    }
    if (sortField === "corporationId" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "corporationId", sortingOrder: "DESC" };
    }

    if (sortField === "country" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "country", sortingOrder: "ASC" };
    }
    if (sortField === "country" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "country", sortingOrder: "DESC" };
    }

    if (sortField === "isWhitelisted" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "isWhitelisted", sortingOrder: "ASC" };
    }
    if (sortField === "isWhitelisted" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "isWhitelisted", sortingOrder: "DESC" };
    }
    return sortingEnumKey;
  };

  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    walletConnect();
  }, []);

  const walletConnect = async () => {
    let web3Modal;
    if (typeof window !== "undefined") {
      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true,
      });
    }
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  const confirmDelete = (publicKey) => {
    setApiLoading(true);

    contract
      .deleteBuyer(publicKey)
      .then((tx) => {
        tx.wait().then((res) => {
          client
            .mutate({
              mutation: DELETE_BUYER,
              variables: { publicKey },
            })
            .then(() => {
              getBuyers();
              setApiLoading(false);
              SuccessNotificationMsg("Buyer deleted successfully.");
            })
            .catch((e) => {
              ErrorNotificationMsg("Error in delete buyer");
              setApiLoading(false);
            });
        });
      })
      .catch((e) => {
        ErrorNotificationMsg(e.message);
        setApiLoading(false);
      });
  };

  const columns = [
    {
      title: "corporation Name",
      dataIndex: "corporationName",
      key: "corporationName",
      sorter: true,
    },
    {
      title: "corporation ID",
      dataIndex: "corporationId",
      key: "corporationId",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      title: "Is Whitelisted",
      key: "isWhitelisted",
      sorter: true,
      render: (record) => (record.isWhitelisted ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space>
          <Link
            to={{
              pathname: `/edit-buyer/${btoa(record.buyerId)}`,
              state: { data: record },
            }}
          >
            <EditOutlined />
          </Link>
          <Popconfirm
            className="action"
            title="Are you sure to delete ?"
            okText="Yes"
            placement="left"
            onConfirm={() => confirmDelete(record.publicKey)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="pagename">Buyer List</div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <div className="heading">
            <Row gutter={{ xs: 0 }} align="middle">
              <Col flex="auto"></Col>
              <Col flex="auto" align="end">
                <Space>
                  <div className="searchhwrap">
                    <Search
                      placeholder="Search here"
                      onChange={searchCallbackDelayed}
                    />
                  </div>

                  <Button
                    type="primary"
                    size="small"
                    htmlType="button"
                    title="Add Business"
                    icon={<PlusCircleOutlined />}
                    onClick={() => props.history.push("/add-buyer")}
                  >
                    Add Buyer
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>

          {state.searchQuery !== "" && buyerList.length > 0 && (
            <Table
              className="table_grid"
              columns={columns}
              rowKey={(record) => record["corporationId"]}
              dataSource={buyerList}
              scroll={{ x: 970 }}
              loading={apiLoading}
              pagination={{
                defaultCurrent: state.pageNumber,
                defaultPageSize: state.limit,
                total: state.total,
                size: "small",
              }}
              onChange={handleTableChange}
            />
          )}

          {state.searchQuery !== "" && buyerList.length === 0 && (
            <div className="no-data">
              <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              <h3>Oops! No buyer found for given search keyword</h3>
            </div>
          )}

          {state.searchQuery === "" && (
            <div className="no-data">
              <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              <h3>No buyer found, please search</h3>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default BuyerList;
