import React, { useState, useEffect } from "react";
import { Col, Row, Collapse } from "antd";

import config from "../../Config";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

const { Panel } = Collapse;

const CRUDetail = (props) => {
  const [tokenDetail, setTokenDetail] = useState([]);
  const contractAddress = config.CONTRACT_ADDRESS;

  useEffect(() => {
    if (props.location.state && props.location.state) {
      setTokenDetail(props.location.state.tokenDetail);
    } else {
      window.location.href = "/dashboard";
    }
    // eslint-disable-next-line
  }, []);

  function callback() {}

  return (
    <>
      <div className="pagename">CRU Detail - {tokenDetail?.metadata?.name}</div>
      <div className="content_wrapper dashboardPage">
        <section className="gridwrP">
          <div className="marketplaceDetail">
            <Row gutter={32}>
              <Col xs={24} sm={12} md={12} lg={12}>
                <div className="prodimg">
                  <img src={tokenDetail?.metadata?.image} alt="token-img" />
                  <div className={"outofstock " + tokenDetail.status}>
                    {tokenDetail.status}
                  </div>
                </div>
                <br />
                <Collapse onChange={callback}>
                  <Panel header="Properties" key="1" className="rightcl">
                    <div className="propertyCont">
                      {tokenDetail.metadata && (
                        <Row gutter={16}>
                          {Object.entries(
                            tokenDetail?.metadata?.properties
                          ).map(([key, value], i) => (
                            <Col xs={24} sm={12} md={8} lg={6}>
                              <div className="propcard">
                                {CamelCaseToSpaceLetter(key)}
                                <h4>{value}</h4>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </div>
                  </Panel>
                </Collapse>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} className="rightcl">
                <h3>{tokenDetail?.metadata?.name} </h3>

                <Row gutter={32}>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Token ID</h5>
                    <div className="descp">
                      <a
                        href={`https://mumbai.polygonscan.com/token/${contractAddress}?a=${tokenDetail?.tokenId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {tokenDetail?.tokenId}
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Token Standard</h5>
                    <div className="descp">ERC-1155</div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Blockchain</h5>
                    <div className="descp">Polygon Mumbai</div>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <h5>Quantity</h5>
                    <div className="descp">{tokenDetail.quantity}</div>
                  </Col>
                </Row>

                <h5>Description</h5>
                <div className="descp">
                  {tokenDetail?.metadata?.description}
                </div>
                <br />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
};

export default CRUDetail;
